import { Experiment } from "@amplitude/experiment-js-client";

import { env } from "~config";

export const experiment = Experiment.initialize(
  env.REACT_APP_AMPLITUDE_API_KEY as string,
  {
    serverZone: "eu",
  },
);
