import { z } from "zod";

export const YearsDataSchema = z.object({
  brokerableYears: z.array(z.number()),
  harvestYearData: z.object({
    contractYearsAll: z.array(z.number()),
    harvestYearsAll: z.array(z.number()),
    harvestYearsOpen: z.array(z.number()),
  }),
  yearFlowData: z.object({
    allHarvestYears: z.array(z.number()),
    carbonActiveHarvestYears: z.array(z.number()),
    carbonContractBenefitYears: z.array(z.number()),
    carbonContractYear: z.number(),
    carbonCurrentHarvestYear: z.number(),
    carbonFieldActualsSetupYears: z.array(z.number()),
    carbonFieldActualsSubmitYear: z.number(),
    carbonFieldActualsYears: z.array(z.number()),
    carbonFieldCreatingYear: z.number(),
    carbonFieldSetupYears: z.array(z.number()),
    carbonFieldValidationYears: z.array(z.number()),
  }),
});
