import { FormApi, FormState, ValidationErrors } from "final-form";
import { useRef, useState } from "react";
import { Field, FormSpy, withTypes } from "react-final-form";

import { AlertBoxType } from "@ag/components/AlertBox";
import { SModalTitle } from "@ag/components/Modal";
import { ActionButton } from "@ag/components/buttons";
import { FormInput, FormSelect } from "@ag/components/form";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import {
  mapDeliveryAddresses,
  validate,
} from "~components/GlobalModal/modals/field/components/FieldDetailsForm/helpers";
import {
  SAlert,
  SCancelButton,
  SFooter,
  SFormRow,
} from "~components/GlobalModal/modals/field/components/FieldDetailsForm/styled";
import {
  CoreFieldDataAttribute,
  FieldDetailsFormMode,
} from "~components/GlobalModal/modals/field/components/FieldDetailsForm/types";
import { useFarmsQuery } from "~features/farm";
import { queryClient } from "~lib/react-query";
import {
  CoreFieldData,
  fieldsKeys,
  useUpdateFieldMutation,
} from "~queries/carbon/fields";

const { Form: FinalForm } = withTypes<CoreFieldData>();

type Props = {
  title: string;
  submitButtonText: string;
  fieldId: string;
  formMode?: FieldDetailsFormMode;
  initialData?: Partial<CoreFieldData>;
  onCancel: () => void;
  onSuccess: () => void;
};

const EditFieldForm = ({
  title,
  fieldId,
  initialData,
  submitButtonText,
  formMode,
  onCancel,
  onSuccess,
}: Props) => {
  const { data: farms, isLoading: isFarmsLoading } = useFarmsQuery({
    limit: 100,
  });

  const formRef = useRef<FormApi<CoreFieldData> | null>(null);
  const farmIdRef = useRef<string | null>(null);

  const [isCountryChanged, setIsCountryChanged] = useState(false);

  const updateFieldMutation = useUpdateFieldMutation();

  const isQuickMode = formMode === FieldDetailsFormMode.QuickEdit;

  const getIsCountryChanged = (farmId: string): boolean => {
    if (!initialData) {
      return false;
    }

    const initialFarmId = initialData.carbonFarmId;

    const initialFarm = farms?.items.find(farm => farm.id === initialFarmId);
    const currentFarm = farms?.items.find(farm => farm.id === farmId);

    const initialCountryCode = initialFarm?.carbonCountry.code;
    const currentCountryCode = currentFarm?.carbonCountry.code;

    return initialCountryCode !== currentCountryCode;
  };

  const handleFarmChanged = (farmId: string): void => {
    const isDifferentCountry = getIsCountryChanged(farmId);

    setIsCountryChanged(isDifferentCountry);

    if (!formRef.current) {
      return;
    }
  };

  const handleFormSubmitted = (data: CoreFieldData) => {
    updateFieldMutation.mutate(
      {
        fieldId,
        field: {
          ...data,
          sizeHa: Number(data.sizeHa),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(fieldsKeys.field({ fieldId }));

          ToastNotification.success(
            I18n.t("js.carbon.field_details.field_updated_success"),
          );

          onSuccess();
        },
      },
    );
  };

  return (
    /**
     * TODO: To be removed after ct-button is styled in styled-components
     * https://app.asana.com/0/1120148049704812/1199619057156647
     */
    <div className="carbon" data-testid="addFieldModal">
      <SModalTitle>{title}</SModalTitle>

      {isCountryChanged && (
        <SAlert type={AlertBoxType.Warning}>
          {I18n.t("js.carbon.reset_data_warning")}
        </SAlert>
      )}

      <FinalForm
        initialValues={initialData}
        onSubmit={handleFormSubmitted}
        validate={(
          values,
        ): ValidationErrors | Promise<ValidationErrors> | undefined =>
          validate({ values })
        }
        render={({ form, handleSubmit }): React.ReactNode => {
          formRef.current = form;

          return (
            <>
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }: FormState<CoreFieldData>): void => {
                  if (farmIdRef.current !== values.carbonFarmId) {
                    handleFarmChanged(values.carbonFarmId);
                  }

                  farmIdRef.current = values.carbonFarmId;
                }}
              />

              <SFormRow>
                <Field
                  name={CoreFieldDataAttribute.Name}
                  label={I18n.t("js.carbon.field_name_or_identifier")}
                  testTag="fieldDetailsForm-nameInput"
                  component={FormInput}
                />
              </SFormRow>

              <SFormRow>
                <Field
                  name={CoreFieldDataAttribute.SizeHa}
                  label={I18n.t("js.carbon.field_size_in_ha")}
                  placeholder={I18n.t("js.carbon.size_of_field_in_ha")}
                  testTag="fieldDetailsForm-sizeHaInput"
                  type="number"
                  component={FormInput}
                />
              </SFormRow>

              {(!formMode || !isQuickMode) && (
                <SFormRow>
                  <Field
                    loading={isFarmsLoading || farms?.items?.length === 0}
                    name={CoreFieldDataAttribute.CarbonFarmId}
                    label={I18n.t("js.carbon.nearest_address")}
                    component={FormSelect}
                    options={mapDeliveryAddresses(farms?.items)}
                    testTag="fieldDetails-addressInput"
                  />
                </SFormRow>
              )}

              <SFooter>
                <SCancelButton
                  type="text"
                  testTag="fieldDetails-cancelButton"
                  onClick={onCancel}
                >
                  {I18n.t("js.shared.cancel")}
                </SCancelButton>

                <ActionButton
                  HTMLType="submit"
                  testTag="fieldDetailsForm-submitButton"
                  isLoading={updateFieldMutation.isLoading}
                  onClick={handleSubmit}
                >
                  {submitButtonText}
                </ActionButton>
              </SFooter>
            </>
          );
        }}
      />
    </div>
  );
};

export default EditFieldForm;
