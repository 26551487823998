import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect } from "@ag/design-system/molecules";
import { box } from "@ag/design-system/utils";
import {
  ButtonSelectField,
  InputField,
  setInputValueAsNumber,
} from "@ag/form-fields";
import I18n from "@ag/i18n";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import { FertiliserErrors } from "../../types/fertiliser-type";
import {
  SyntheticPhosphorusType,
  SyntheticPotassiumType,
} from "../../types/field-definition-data";
import { YearKey } from "../crop-form-section/crop-form-section";

const SyntheticFertiliserFormQuestions = ({
  index,
  yearKey,
}: {
  index: number;
  yearKey: YearKey;
}) => {
  const [isDisabled] = useAtom(isDisabledAtom);
  const { register, control, formState } =
    useFormContext<FieldDefinitionData>();

  const errors =
    (formState.errors.verraQuestionnaire?.[yearKey]?.fertilisers?.[
      index
    ] as FertiliserErrors) ?? {};

  return (
    <>
      <div style={{ width: "100%", maxWidth: 221 }}>
        <InputField
          {...register(
            `verraQuestionnaire.${yearKey}.fertilisers.${index}.syntheticNitrogenApplicationRate`,
            { setValueAs: setInputValueAsNumber },
          )}
          type="number"
          step=".01"
          label={I18n.t(
            "js.carbon.field_details.field_definition.questionnaire.fertiliser.n_application_rate",
          )}
          rightAddon={I18n.t("js.carbon.kg_ha")}
          placeholder={I18n.t("js.shared.enter")}
          data-testid={`synthetic-nitrogen-application-rate-${index}`}
          error={errors?.syntheticNitrogenApplicationRate}
          isDisabled={isDisabled}
          onWheel={e => e.currentTarget.blur()}
        />
      </div>

      <div className={box({ gap: 24, align: "start" })}>
        <div className={box({ gap: 8, align: "start" })}>
          <InputField
            {...register(
              `verraQuestionnaire.${yearKey}.fertilisers.${index}.syntheticPhosphorusApplicationRate`,
              { setValueAs: setInputValueAsNumber },
            )}
            type="number"
            step=".01"
            label={I18n.t(
              "js.carbon.field_details.field_definition.questionnaire.fertiliser.p_application_rate",
            )}
            rightAddon={I18n.t("js.carbon.kg_ha")}
            placeholder={I18n.t("js.shared.enter")}
            data-testid={`synthetic-phosphorus-application-rate-${index}`}
            error={errors?.syntheticPhosphorusApplicationRate}
            isDisabled={isDisabled}
            onWheel={e => e.currentTarget.blur()}
          />

          <Controller
            name={`verraQuestionnaire.${yearKey}.fertilisers.${index}.syntheticPhosphorusType`}
            control={control}
            render={({ field }) => (
              <ButtonSelectField
                {...field}
                label={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fertiliser.phosphorus_type",
                )}
                testId={`synthetic-phosphorus-type-${index}`}
                error={errors?.syntheticPhosphorusType}
                isDisabled={isDisabled}
              >
                <ButtonSelect.Option value={SyntheticPhosphorusType.P}>
                  P
                </ButtonSelect.Option>

                <ButtonSelect.Option value={SyntheticPhosphorusType.P_2O5}>
                  P2O5
                </ButtonSelect.Option>
              </ButtonSelectField>
            )}
          />
        </div>

        <div className={box({ gap: 8, align: "start" })}>
          <InputField
            {...register(
              `verraQuestionnaire.${yearKey}.fertilisers.${index}.syntheticPotassiumApplicationRate`,
              { setValueAs: setInputValueAsNumber },
            )}
            type="number"
            step=".01"
            label={I18n.t("js.carbon.field.fertilisers.k_application_rate")}
            placeholder={I18n.t("js.shared.enter")}
            rightAddon={I18n.t("js.carbon.kg_ha")}
            data-testid={`synthetic-potassium-application-rate-${index}`}
            error={errors?.syntheticPotassiumApplicationRate}
            isDisabled={isDisabled}
            onWheel={e => e.currentTarget.blur()}
          />

          <Controller
            name={`verraQuestionnaire.${yearKey}.fertilisers.${index}.syntheticPotassiumType`}
            control={control}
            render={({ field }) => (
              <ButtonSelectField
                {...field}
                label={I18n.t(
                  "js.carbon.field_details.field_definition.questionnaire.fertiliser.potassium_type",
                )}
                testId={`synthetic-potassium-type-${index}`}
                error={errors?.syntheticPotassiumType}
                isDisabled={isDisabled}
              >
                <ButtonSelect.Option value={SyntheticPotassiumType.K}>
                  K
                </ButtonSelect.Option>

                <ButtonSelect.Option value={SyntheticPotassiumType.K_2O}>
                  K2O
                </ButtonSelect.Option>
              </ButtonSelectField>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default SyntheticFertiliserFormQuestions;
