import axios from "axios";
import sortBy from "lodash/sortBy";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { z } from "zod";

import { ISOFertiliser, ISOFertiliserSchema } from "@ag/carbon/entities";
import { Locale } from "@ag/i18n";
import { CommonResponse } from "@ag/utils/types";

import { ISOCropTypeSchema } from "../schemas";
import { ISOCropType } from "../types";
import { YearsDataSchema } from "./schemas";
import {
  FetchISOCropTypesArgs,
  FetchISOFertilisersArgs,
  YearsData,
} from "./types";

export const initialResourcesKeys = {
  all: ["carbon-initial-resources"] as const,

  initialResources: (locale: Locale | undefined) =>
    [...initialResourcesKeys.all, "initial-resources", { locale }] as const,

  fertilisersAll: () => [...initialResourcesKeys.all, "fertilisers"] as const,
  fertilisers: (args: FetchISOFertilisersArgs) =>
    [...initialResourcesKeys.fertilisersAll(), args] as const,

  cropTypesAll: () => [...initialResourcesKeys.all, "crop-types"] as const,
  cropTypes: (args: FetchISOCropTypesArgs) =>
    [...initialResourcesKeys.cropTypesAll(), args] as const,

  yearsData: ["years-data"] as const,
  supportedCarbonContractLocales: [
    "supported-carbon-contract-locales",
  ] as const,
};

/**
 * QUERY FUNCTIONS
 */
const fetchISOFertilisers = async (params: FetchISOFertilisersArgs) => {
  const response = await axios.get<CommonResponse<ISOFertiliser[]>>(
    "/api/v1/carbon-fertilisers",
    {
      params: {
        ...params,
      },
      schema: z.array(ISOFertiliserSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

const fetchISOCropTypes = async (params: FetchISOCropTypesArgs) => {
  const response = await axios.get<CommonResponse<ISOCropType[]>>(
    "/api/v1/carbon-crop-types",
    {
      params: {
        ...params,
      },
      apiSource: "node-carbon",
      schema: z.array(ISOCropTypeSchema),
    },
  );

  return response.data.success!.data;
};

const fetchYearsData = async () => {
  const response = await axios.get<CommonResponse<YearsData>>(
    "/api/v1/carbon-users/years-data",
    {
      schema: YearsDataSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

const fetchSupportedCarbonContractLocales = async () => {
  const response = await axios.get<CommonResponse<Locale[]>>(
    "/api/v1/contracts/supported-locales",
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useISOFertilisersQuery = (params: FetchISOFertilisersArgs) =>
  useQuery(
    initialResourcesKeys.fertilisers(params),
    () => {
      if (
        !params.filters ||
        !params.filters.harvestYear ||
        !params.filters.methodologyVersion
      ) {
        return Promise.resolve(undefined);
      }

      return fetchISOFertilisers(params);
    },
    {
      staleTime: Infinity,
      enabled: Boolean(
        params.filters &&
          params.filters.harvestYear &&
          params.filters.methodologyVersion,
      ),
    },
  );

export const useISOCropTypesQuery = (params: FetchISOCropTypesArgs) =>
  useQuery(
    initialResourcesKeys.cropTypes(params),
    () => {
      if (
        !params.filters ||
        !params.filters.harvestYear ||
        !params.filters.methodologyVersion
      ) {
        return Promise.resolve(undefined);
      }
      return fetchISOCropTypes(params);
    },
    {
      staleTime: Infinity,
      enabled: Boolean(
        params.locale &&
          params.filters &&
          params.filters.harvestYear &&
          params.filters.methodologyVersion,
      ),
      select: useCallback((cropTypes: ISOCropType[] | undefined) => {
        if (!cropTypes) return;

        return sortBy<ISOCropType>(cropTypes, ["name"]);
      }, []),
    },
  );

type UseYearsDataQueryOptions<T> = {
  select?: (data: YearsData) => T;
};

export const useYearsDataQuery = <T = YearsData>(
  options?: UseYearsDataQueryOptions<T>,
) =>
  useQuery(initialResourcesKeys.yearsData, () => fetchYearsData(), {
    staleTime: Infinity,
    ...options,
  });

export const useCurrentHarvestYear = () =>
  useYearsDataQuery<number>({
    select: data => data.yearFlowData.carbonCurrentHarvestYear,
  });

export const useSupportedCarbonContractLocalesQuery = () =>
  useQuery(
    initialResourcesKeys.supportedCarbonContractLocales,
    fetchSupportedCarbonContractLocales,
    {
      staleTime: Infinity,
    },
  );
