import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonSelect, Select } from "@ag/design-system/molecules";
import { ButtonSelectField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import FormSection from "~components/form-section";
import { NameIdentifier as OrganicFertiliserOption } from "~schemas/name-identifier";

import { isDisabledAtom } from "../../atoms/is-disabled-atom";
import { type FieldDefinitionData } from "../../schemas/field-definition-form";
import {
  Fertiliser,
  FertiliserErrors,
  FertiliserType,
} from "../../types/fertiliser-type";
import AdditionalInformationTip from "../additional-information-tip";
import { YearKey } from "../crop-form-section/crop-form-section";
import OrganicFertiliserFormQuestions from "./organic-fertiliser-form-questions";
import SyntheticFertiliserFormQuestions from "./synthetic-fertiliser-form-questions";

const FertiliserFormQuestions = ({
  index,
  yearKey,
  fertiliser,
  organicFertiliserTypes,
}: {
  index: number;
  yearKey: YearKey;
  fertiliser: Fertiliser;
  organicFertiliserTypes: OrganicFertiliserOption[];
}) => {
  const [isDisabled] = useAtom(isDisabledAtom);
  const { control, formState } = useFormContext<FieldDefinitionData>();

  const errors =
    (formState.errors.verraQuestionnaire?.[yearKey]?.fertilisers?.[
      index
    ] as FertiliserErrors) ?? {};

  return (
    <FormSection.Card>
      <Controller
        name={`verraQuestionnaire.${yearKey}.fertilisers.${index}.type`}
        control={control}
        render={({ field }) => (
          <ButtonSelectField
            {...field}
            label={I18n.t(
              "js.carbon.field_details.field_definition.questionnaire.fertiliser.label",
            )}
            testId={`fertiliser-type-${index}`}
            error={errors?.type}
            isDisabled={isDisabled}
            isRequired
          >
            <ButtonSelect.Option value={FertiliserType.SYNTHETIC}>
              {I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fertilisers.synthetic",
              )}
            </ButtonSelect.Option>

            <ButtonSelect.Option value={FertiliserType.ORGANIC}>
              {I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fertilisers.organic",
              )}
            </ButtonSelect.Option>

            <ButtonSelect.Option value={FertiliserType.MIXED}>
              {I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fertilisers.synthetic_organic",
              )}
            </ButtonSelect.Option>

            <ButtonSelect.Option value={FertiliserType.NONE}>
              {I18n.t(
                "js.carbon.field_details.field_definition.questionnaire.fertilisers.none",
              )}
            </ButtonSelect.Option>
          </ButtonSelectField>
        )}
      />
      {[
        FertiliserType.SYNTHETIC,
        FertiliserType.ORGANIC,
        FertiliserType.MIXED,
      ].includes(fertiliser?.type as FertiliserType) && (
        <>
          {[FertiliserType.ORGANIC, FertiliserType.MIXED].includes(
            fertiliser?.type as FertiliserType,
          ) &&
            organicFertiliserTypes && (
              <div style={{ width: "100%", maxWidth: 221 }}>
                <Controller
                  name={`verraQuestionnaire.${yearKey}.fertilisers.${index}.organicType`}
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      value={field.value ?? null}
                      label={I18n.t(
                        "js.carbon.field_details.field_definition.questionnaire.fertilisers.type_of_organic",
                      )}
                      tooltip={I18n.t(
                        "js.carbon.field_details.field_definition.questionnaire.fertilisers.type_of_organic_tip",
                      )}
                      placeholder={I18n.t("js.shared.select")}
                      testId={`organic-type-${index}`}
                      error={errors?.organicType}
                      isDisabled={isDisabled}
                      isRequired
                    >
                      {organicFertiliserTypes.map((type, index) => (
                        <Select.Option
                          key={`fertiliser-opt-${index}`}
                          value={type.identifier}
                        >
                          {type.name}
                        </Select.Option>
                      ))}
                    </SelectField>
                  )}
                />
              </div>
            )}

          <AdditionalInformationTip />

          {[FertiliserType.ORGANIC, FertiliserType.MIXED].includes(
            fertiliser?.type as FertiliserType,
          ) && (
            <OrganicFertiliserFormQuestions index={index} yearKey={yearKey} />
          )}

          {[FertiliserType.SYNTHETIC, FertiliserType.MIXED].includes(
            fertiliser?.type as FertiliserType,
          ) && (
            <SyntheticFertiliserFormQuestions index={index} yearKey={yearKey} />
          )}
        </>
      )}
    </FormSection.Card>
  );
};

export default FertiliserFormQuestions;
