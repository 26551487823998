import axios from "axios";
import { QueryObserverOptions, useQuery } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { NameIdentifier, NameIdentifierSchema } from "~schemas/name-identifier";

const cropTypesQueryKey = "verra-questionnaire-crop-types" as const;

const getVerraCropTypes = async (locale: string) => {
  const response = await axios.get<CommonResponse<NameIdentifier[]>>(
    `/api/v1/carbon-field-definition-verra-questionnaires/crops`,
    {
      params: { locale },
      schema: NameIdentifierSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useVerraCropTypesQuery = (
  locale: string,
  opts?: Pick<QueryObserverOptions, "enabled">,
) =>
  useQuery(cropTypesQueryKey, () => getVerraCropTypes(locale), {
    ...opts,
    onError: error => {
      ToastNotification.error(error);
    },
  });
