import { AxiosError } from "axios";
import { z } from "zod";

import { GeoJSONGeometry, LatLng } from "@ag/map/types";
import { Meta, MetaArgs } from "@ag/utils/types";

import { FarmFieldSchema, FieldSchema } from "./schemas";

/**
 * ENTITIES
 */
export type Field = z.infer<typeof FieldSchema>;

export type FarmField = z.infer<typeof FarmFieldSchema>;

/**
 * RESPONSES
 */
export type FieldsResponse = {
  items: Field[];
  meta: Meta;
};

/**
 * QUERY KEY ARGS
 */
export type FieldsKeyArgs = {
  filters?: FieldsFilters;
  meta?: MetaArgs;
  harvestYear?: number;
};

export type FieldKeyArgs = {
  fieldId: string | undefined;
  harvestYear?: number;
};

/**
 * QUERY FUNCTION ARGS
 */
export type FetchFieldsArgs = {
  filters?: FieldsFilters;
  meta?: MetaArgs;
  includeBaseline?: boolean;
};

export type FetchFieldArgs = {
  fieldId: string | number;
  harvestYear?: number;
};

/**
 * MUTATION ARGS
 */
export type UseUpdateFieldMutationArgs = {
  fieldId: string;
};

/**
 * QUERY FUNCTION ARGS
 */

/**
 * QUERY ARGS
 */
export type UseFieldsQueryArgs = {
  filters?: FieldsFilters;
  meta?: MetaArgs;
  harvestYear?: number;
};

export type UseFieldsQueryOptions<T> = {
  enabled?: boolean;
  keepPreviousData?: boolean;
  select?: (fields: FieldsResponse) => T;
  onSuccess?: (fields: T) => void;
};

export type UseFieldQueryArgs = {
  fieldId: string | undefined;
  harvestYear?: number;
};

export type UseFieldQueryOptions = {
  retry?: number;
  enabled?: boolean;
  onError?: (error?: AxiosError) => void;
};

/**
 * MUTATION FUNCTION ARGS
 */
export type CreateFieldBulkArgs = {
  fields: CoreFieldData[];
};

export type UpdateFieldArgs = {
  fieldId: string;
  field: Partial<CoreFieldData>;
};

export type ValidateFieldArgs = {
  carbonFieldIds: string[];
  scheduledEvent: ScheduledEvent;
  harvestYear?: number;
};

/**
 * MISC
 */
export type CoreFieldData = {
  name: string;
  sizeHa: number;
  carbonFarmId: string;
  position: LatLng;
  coordinates?: LatLng[];
  boundaries?: GeoJSONGeometry;
};

export type FieldsFilters = Partial<{
  harvestYear: number;
  farmId: string;
  ids: number[];
  activeCarbonContractIds: string[];
  pendingContract: boolean;
  pendingValidation: boolean;
  underValidation: boolean;
  validationStatuses: FieldValidationStatus[];
  contracted: boolean;
  contractedYear: number;
  name: string;
  expired: boolean;
  actualsSubmittable: boolean;
  definitionSubmitted: boolean;
}>;

export type ConnectedFarmerFieldsFilters = Partial<{
  harvestYear: number;
  carbonFarmId: string[];
  id: number[];
  carbonContractId: string;
  pendingContract: boolean;
  pendingValidation: boolean;
  underValidation: boolean;
  validationStatus: FieldValidationStatus[];
  withoutActiveContract: boolean;
  contractedYear: number;
  fieldName: string;
  expired?: boolean;
}>;

export type ScheduledEvent = {
  eventUri: string;
  inviteeUri: string;
};

export enum FieldStep {
  Definition = "definition",
  Actuals = "actuals",
}

export enum FieldValidationStatus {
  New = "new",
  InProgress = "in_progress",
  Validated = "validated",
  Rejected = "rejected",
}

export const isFarmField = (field: FarmField | Field): field is FarmField =>
  (field as FarmField).statistics !== undefined;

export type FetchFarmFieldsFilters = {
  id?: number[];
  validationStatus?: FieldValidationStatus[];
  actualsSubmittable?: boolean;
  expired?: boolean;
};
