import { useState } from "react";

import { Toggle } from "@ag/design-system/atoms";
import { Locale } from "@ag/i18n";
import { API_URL } from "@ag/utils/constants";

import { env } from "~config";
import { useLocaleContext } from "~contexts/locales";
import { useSessionContext } from "~features/authentication";

import * as styles from "./app-mode-frame.css";

const AppModeFrame = () => {
  const { currentUser, isAdminMode } = useSessionContext();
  const { setLocale } = useLocaleContext();

  const [currentDisplayLanguage, setCurrentDisplayLanguage] = useState<Locale>(
    Locale.En,
  );

  const isUsingRubyProductionApi =
    env.REACT_APP_API_RUBY_BASE_URL === API_URL.RUBY_PRODUCTION;
  const isUsingNodeAuthProductionApi =
    env.REACT_APP_API_NODE_AUTH_BASE_URL === API_URL.NODE_AUTH_PROD;

  const handleToggleUserLanguage = () => {
    if (!currentUser) return;
    if (currentDisplayLanguage == currentUser.locale) {
      setLocale(Locale.En);
      setCurrentDisplayLanguage(Locale.En);
      return;
    } else {
      setLocale(currentUser.locale);
      setCurrentDisplayLanguage(currentUser.locale);
    }
  };

  if (
    (isUsingRubyProductionApi || isUsingNodeAuthProductionApi) &&
    env.REACT_APP_APP_ENVIRONMENT !== "production"
  ) {
    return (
      <div className={styles.root}>
        <span className={styles.label}>Production Mode</span>
      </div>
    );
  }

  if (isAdminMode) {
    return (
      <div className={styles.root}>
        <span className={styles.info}>
          <span data-testid="admin-mode-SignedAs" className={styles.label}>
            Signed as: {currentUser?.email}
            {currentUser?.locale != Locale.En && (
              <span className={styles.toggleContainer}>
                User language
                <Toggle
                  className={styles.toggle}
                  checked={currentDisplayLanguage == currentUser?.locale}
                  onChange={handleToggleUserLanguage}
                />
              </span>
            )}
          </span>
        </span>
      </div>
    );
  }

  return null;
};

export default AppModeFrame;
