import axios from "axios";
import { useMutation } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~lib/react-query";

import { fieldsKeys } from "./queries";
import { Field, UpdateFieldArgs, ValidateFieldArgs } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const updateField = async ({ fieldId, field }: UpdateFieldArgs) => {
  const response = await axios.put<CommonResponse<Field>>(
    `/api/v1/carbon-fields/${fieldId}`,
    field,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

const deleteField = async (fieldId: string) => {
  await axios.delete(`/api/v1/carbon-fields/${fieldId}`, {
    apiSource: "node-carbon",
  });
};

const validateField = async ({
  carbonFieldIds,
  scheduledEvent,
  harvestYear,
}: ValidateFieldArgs) => {
  await axios.post(
    "/api/v1/carbon-validation-requests",
    {
      carbonFieldIds,
      scheduledEvent,
      harvestYear,
    },
    {
      apiSource: "node-carbon",
    },
  );
};

/**
 * MUTATION HOOKS
 */
export const useUpdateFieldMutation = () => {
  return useMutation(updateField, {
    onSuccess: () => {
      queryClient.invalidateQueries(fieldsKeys.all);
      queryClient.invalidateQueries("farm-fields");
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
};

export const useDeleteFieldMutation = () =>
  useMutation(deleteField, {
    onSuccess: () => {
      queryClient.invalidateQueries(fieldsKeys.fieldsAll());
      queryClient.invalidateQueries("farm-fields");
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });

export const useValidateFieldMutation = () =>
  useMutation(validateField, {
    onSuccess: () => {
      queryClient.invalidateQueries(fieldsKeys.all);
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
