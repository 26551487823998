import axios from "axios";
import { QueryObserverOptions, useQuery } from "react-query";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { NameIdentifier, NameIdentifierSchema } from "~schemas/name-identifier";

const fertilisersQueryKey = "verra-questionnaire-fertilisers" as const;

const getVerraFertilisers = async (locale: string) => {
  const response = await axios.get<CommonResponse<NameIdentifier[]>>(
    `/api/v1/carbon-field-definition-verra-questionnaires/fertilisers`,
    {
      params: { locale },
      schema: NameIdentifierSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useVerraFertilisersQuery = (
  locale: string,
  opts?: Pick<QueryObserverOptions, "enabled">,
) =>
  useQuery(fertilisersQueryKey, () => getVerraFertilisers(locale), {
    ...opts,
    onError: error => {
      ToastNotification.error(error);
    },
  });
