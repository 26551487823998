import { useLayoutEffect, useState } from "react";
import {
  NavigateOptions,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { UserRole } from "@ag/utils/types";

import { CurrentUser, useSessionContext } from "~features/authentication";

export type NavigateData = {
  to: string;
  options?: NavigateOptions;
};

type GetRedirectDataArgs = {
  pathname: string;
  searchParams: URLSearchParams;
  currentUser: CurrentUser | undefined;
};

/**
 * Get redirect path based on current location.
 *
 * ! IMPORTANT: Order does matter !
 *
 * @param pathname The location pathname
 * @param isLoggedIn Whether the user is logged in
 * @returns Path name to which the user should be redirected
 */
const getRedirectData = ({
  pathname,
  searchParams,
  currentUser,
}: GetRedirectDataArgs): NavigateData | null => {
  /**
   * External campaign e.g http://www.agreena.com pathname - redirect to root page
   */
  if (pathname.includes("http")) {
    return { to: "/" };
  }

  /**
   * Root page for unauthorized users - login page redirect
   */
  if (pathname === "/" && !currentUser) {
    return { to: "/session-check" };
  }

  /**
   * Login page for authorized users - redirect to accessed page or root path
   */
  if (pathname === "/login" && currentUser) {
    return { to: searchParams.get("redirect") || "/" };
  }

  /**
   * Registration for unauthorized users - no redirect
   */
  if (pathname.includes("registration") && !currentUser) {
    return null;
  }

  /**
   * Redirect after the new user was created or logged in user trying to access registration
   */
  if (pathname.includes("registration") && currentUser) {
    return { to: "/" };
  }

  /**
   * Unauthorized users - no redirect AuthorizedRoute component is handling it
   */
  if (!currentUser) {
    return null;
  }

  /**
   * Legacy advisor role - redirect to unsupported page
   */
  if (currentUser.roles.includes(UserRole.Advisor)) {
    return { to: "/unsupported" };
  }

  /**
   * Email sent to user waiting for confirmation
   */
  if (pathname !== "/confirmation/email-error" && !currentUser.confirmedAt) {
    return { to: "/confirmation/email-request" };
  }

  /**
   * Specific URL requested - no redirect
   */
  if (pathname !== "/") {
    return null;
  }

  /**
   * User without company - redirect to missing profile information page
   */
  if (!currentUser.company) {
    return { to: "/missing-profile-information", options: { replace: true } };
  }

  /**
   * Redirect to Carbon
   */
  return { to: "/carbon", options: { replace: true } };
};

export const useUserRedirection = (): NavigateData | null => {
  const [redirectionData, setRedirectionData] = useState<NavigateData | null>(
    null,
  );

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { currentUser } = useSessionContext();

  useLayoutEffect(() => {
    const redirectData = getRedirectData({
      pathname,
      searchParams,
      currentUser,
    });

    setRedirectionData(redirectData);
  }, [pathname, searchParams, currentUser]);

  return redirectionData;
};
